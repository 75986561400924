<template>

	<div class="ztnrbg">


		<div class="index_mv ztnr">
			<leftMeun></leftMeun>
			<div class="right_nr">
				<rightMeun></rightMeun>
				<div class="list category_news_article" style="border: 0;">
					<div class="t">
						南科大档案馆人流量
					</div>
					<ve-line :data="chartDat"></ve-line>
				</div>
			</div>
            <newRightMeun></newRightMeun>
		</div>
	</div>
</template>

<script>
	import leftMeun from '@/components/leftMeun.vue'
	import rightMeun from '@/components/rightMeun.vue'
    import newRightMeun from '@/components/newRightMeun.vue'
	export default {
		components: {
			leftMeun,
			rightMeun,
             newRightMeun
		},
		data: function() {
			return {
				chartDat: {
					columns: ['日期', '访问用户', '下单用户', '下单率'],
					rows: [{
							'日期': '1/1',
							'访问用户': 133,
							'下单用户': 193,
							'下单率': 10.32
						},
						{
							'日期': '1/2',
							'访问用户': 330,
							'下单用户': 330,
							'下单率': 80.26
						},
						{
							'日期': '1/3',
							'访问用户': 223,
							'下单用户': 323,
							'下单率': 89.76
						},
						{
							'日期': '1/4',
							'访问用户': 123,
							'下单用户': 123,
							'下单率': 14.49
						},
						{
							'日期': '1/5',
							'访问用户': 392,
							'下单用户': 342,
							'下单率': 11.323
						},
						{
							'日期': '1/6',
							'访问用户': 493,
							'下单用户': 423,
							'下单率': 13.78
						}
					]
				}
			}
		},
		created() {
			var that = this

			that.chartDat = {
				columns: ['日期', '访问用户', '下单用户', '下单率'],
				rows: [{
						'日期': '1/1',
						'访问用户': 133,
						'下单用户': 193,
						'下单率': 10.32
					},
					{
						'日期': '1/2',
						'访问用户': 330,
						'下单用户': 330,
						'下单率': 80.26
					},
					{
						'日期': '1/3',
						'访问用户': 223,
						'下单用户': 323,
						'下单率': 89.76
					},
					{
						'日期': '1/4',
						'访问用户': 123,
						'下单用户': 123,
						'下单率': 14.49
					},
					{
						'日期': '1/5',
						'访问用户': 392,
						'下单用户': 342,
						'下单率': 11.323
					},
					{
						'日期': '1/6',
						'访问用户': 493,
						'下单用户': 423,
						'下单率': 13.78
					}
				]
			}
			that.$emit('getLoad', true);
		}
	}
</script>

<style>
	.t {
		text-align: center;
		font-size: 24px;
		padding: 0 0 20px;
	}
</style>
